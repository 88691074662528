// import i18n from '@/i18n/index'

export default {
  tableDataItem: [{
    prop: 'id',
    label: '',
    Object: 'value',
    width: '80'
  }, {
    prop: 'name',
    label: '',
    Object: 'value',
    width: '180'
  }, {
    prop: 'phone_number',
    label: '',
    Object: 'value',
    width: '180'
  }, {
    prop: 'email',
    label: '',
    Object: 'value',
    width: '180'
  }, {
    prop: 'had_past_experience',
    label: '',
    Object: 'value',
    width: '120'
  }, {
    prop: 'support_temp_sensitive_delivery',
    label: '',
    Object: 'value',
    width: '120'
  }, {
    prop: 'support_bulk_delivery',
    label: '',
    Object: 'value',
    width: '120'
  }]
}
